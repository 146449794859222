// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-section-js": () => import("./../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */),
  "component---src-templates-section-content-js": () => import("./../src/templates/section-content.js" /* webpackChunkName: "component---src-templates-section-content-js" */),
  "component---src-templates-book-js": () => import("./../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-subsection-js": () => import("./../src/templates/subsection.js" /* webpackChunkName: "component---src-templates-subsection-js" */),
  "component---src-templates-subsection-content-js": () => import("./../src/templates/subsection-content.js" /* webpackChunkName: "component---src-templates-subsection-content-js" */),
  "component---src-templates-books-js": () => import("./../src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-tex-js": () => import("./../src/templates/tex.js" /* webpackChunkName: "component---src-templates-tex-js" */),
  "component---src-templates-ref-js": () => import("./../src/templates/ref.js" /* webpackChunkName: "component---src-templates-ref-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latex-js": () => import("./../src/pages/latex.js" /* webpackChunkName: "component---src-pages-latex-js" */)
}

